<template>
  <v-card
    max-width="400"
    outlined
    color="transparent"
  >
    <v-card-title class="pt-1 pb-1">
      <ProviderIcon :provider="shipment.provider" />
      <CarrierIcon :carrier="shipment.carrier" />
      <span
        v-if="shipment.tracking_number"
      >
        {{ shipment.tracking_number }}
      </span>
      <v-spacer />
      <template
        v-if="shipment.provider_reference"
      >
        <v-btn
          color="indigo lighten-2"
          icon
          target="_blank"
          :href="getProviderLink(shipment)"
        >
          <v-icon small>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          icon
          color="grey lighten-1"
        >
          <v-icon small>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text class="pt-1 pb-1">
      <StatusChip :status="shipment.status" />
    </v-card-text>
    <v-card-actions
      v-if="shipment.provider_reference === null
        && shipment.provider !== 'EXTERNAL' && shipment.provider !== 'BERRY_SERVICES'"
    >
      <v-btn
        small
        block
        color="red darken-2"
        class="white--text px-5"
        target="_blank"
        :href="'/shipments/' + shipment.id"
      >
        <v-icon left>
          mdi-alert
        </v-icon>Créer colis
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else-if="shipment.status === 'AWAITING_LABEL_GENERATION'">
      <v-btn
        small
        block
        raised
        color="green darken-1"
        target="_blank"
        :href="'/shipments/' + shipment.id"
      >
        <v-icon left>
          mdi-package
        </v-icon>Créer Bordereau
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <template v-if="shipment.tracking_url">
        <v-btn
          icon
          color="indigo lighten-2"
          link
          target="_blank"
          :href="shipment.tracking_url"
        >
          <v-icon small>
            mdi-web
          </v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          icon
          color="grey lighten-1"
        >
          <v-icon small>
            mdi-web
          </v-icon>
        </v-btn>
      </template>

      <template v-if="shipment.label_url">
        <v-btn
          icon
          color="indigo lighten-2"
          link
          target="_blank"
          :href="'/shipments/' + shipment.id"
        >
          <v-icon small>
            mdi-truck-delivery
          </v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          icon
          color="grey lighten-1"
        >
          <v-icon small>
            mdi-truck-delivery
          </v-icon>
        </v-btn>
      </template>

      <template>
        <v-btn
          icon
          color="indigo lighten-2"
          target="_blank"
          :href="'/shipments/' + shipment.id"
        >
          <v-icon small>
            mdi-receipt
          </v-icon>
        </v-btn>
      </template>
      <v-spacer />
      <v-btn
        icon
        small
        color="indigo lighten-2"
        target="_blank"
        :href="'/shipments/' + shipment.id"
      >
        <v-icon small>
          mdi-data-matrix-edit
        </v-icon>
      </v-btn>
      <v-btn
        icon
        color="indigo lighten-2"
        target="_blank"
        :href="'/shipments/' + shipment.id"
      >
        <v-icon small>
          mdi-email-edit-outline
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CarrierIcon from '@/components/base/Shipment/CarriererIcon.vue'
import ProviderIcon from '@/components/base/Shipment/ProviderIcon.vue'
import shipment from '@/mixins/shipment'
import StatusChip from '@/components/base/Shipment/StatusChip.vue'

export default {
  name: 'ShipmentListDetail',
  components: { CarrierIcon, ProviderIcon, StatusChip },
  mixins: [ shipment ],
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
</style>
